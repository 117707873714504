const commonClasses = [
  "tracking-wider",
  "uppercase",
  "font-standard",
  "hover:opacity-80",
  "transition-all",
  "duration-200",
  "ease-in-out",
];
const standardClasses = [...commonClasses, "text-gray-300", "font-medium"];
const quicklinkClasses = [
  ...commonClasses,
  "text-distinct-grey-medium",
  "text-lg",
  "leading-relaxed",
  "mb-2",
];

const homeNavClasses = [...commonClasses, "opacity-90", "px-6"];

const classes = {
  homeNav: homeNavClasses,
  standard: standardClasses,
  quicklink: quicklinkClasses,
};

export default classes;
