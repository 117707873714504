import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "gatsby";

import classes from "./classes";
import ChildrenType from "../../types/children";

const NavLink = ({ children, isExternalLink, link, variant, handleClick }) => (
  <div className="hover:opacity-80 transition ease-in-out duration-200">
    {isExternalLink ? (
      <a
        href={link}
        rel="noopener noreferrer"
        className={classNames(classes[variant])}
        onClick={handleClick}
        target="_blank"
      >
        {children}
      </a>
    ) : (
      <Link to={link} className={classNames(classes[variant])} onClick={handleClick}>
        {children}
      </Link>
    )}
  </div>
);

NavLink.propTypes = {
  variant: PropTypes.string,
  isExternalLink: PropTypes.bool,
  link: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  ...ChildrenType,
};

NavLink.defaultProps = {
  variant: "standard",
  isExternalLink: false,
  handleClick: null,
};
export default NavLink;
