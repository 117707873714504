/**
 * Main Navigation Bar
 * This component is used on generic content templates
 */
import React from "react";
import { Link } from "gatsby";

import NavLink from "./NavLink";
import MobileNav from "./MobileNav";

import logo from "../../images/distinct-logo-small.png";

const Navigation = () => (
  <nav className="flex items-center justify-between bg-black py-4 px-4">
    <div className="flex md:hidden justify-between w-full items-center flex-shrink-0">
      <Link to="/">
        <img src={logo} alt="Logo" style={{ width: 70 }} />
      </Link>
      <MobileNav />
    </div>
    <Link to="/">
      <img src={logo} alt="Logo" style={{ width: 70 }} />
    </Link>

    <div className="hidden md:flex w-2/3 justify-end">
      <ul className="flex items-center mt-1">
        <li className="mr-10">
          <NavLink link="/about-us" isFirst>
            About Us
          </NavLink>
        </li>
        <li className="mr-10">
          <NavLink link="/services">Services</NavLink>
        </li>
        <li className="mr-10">
          <NavLink
            isExternalLink
            link="https://goteamup.com/p/3218555-distinct-performance/"
          >
            Schedule
          </NavLink>
        </li>
        <li>
          <NavLink link="/contact-us" isLast>
            Contact Us
          </NavLink>
        </li>
      </ul>
    </div>
  </nav>
);

export default Navigation;
