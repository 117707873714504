import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Heading from "../heading";
import ArticleCard from "../article-card";

function RelatedArticles({ heading }) {
  // Static Query
  const data = useStaticQuery(graphql`
    query {
      allSanityPost {
        edges {
          node {
            id
            title
            slug {
              current
            }
            featureImage {
              asset {
                id
              }
            }
          }
        }
      }
    }
  `);

  return data.allSanityPost.edges.length >= 3 ? (
    <section className="max-w-screen-desktop">
      <Heading variant="h3" className="mb-16 text-4-1/2xl md:text-5-1/2xl text-center">
        {heading}
      </Heading>

      <div className="flex flex-col justify-between md:flex-row relative block">
        {data.allSanityPost.edges.map(post => {
          const { id, title, featureImage, slug } = post.node;
          return (
            <ArticleCard
              category="test"
              title={title}
              path={`/blog/${slug.current}`}
              key={id}
              featureImage={featureImage}
              className="mb-12 justify-center md:mr-14 last:mr-0"
            />
          );
        })}
      </div>
    </section>
  ) : null;
}

RelatedArticles.propTypes = {
  heading: PropTypes.string,
};

RelatedArticles.defaultProps = {
  heading: "Latest Insights",
};

export default RelatedArticles;
