/**
 * Membership card component.
 * This is the membership card that is seen on the
 * homepage.
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import { projectId, dataset } from "../../utils/sanity";

import Button from "../button";
import Heading from "../heading";

const sanityConfig = { projectId, dataset };

function ArticleCard({ category, title, path, className, featureImage }) {
  const imageAssetId = featureImage?.asset?.id;
  const fluidProps = getFluidGatsbyImage(imageAssetId, { maxWidth: 1024 }, sanityConfig);

  return (
    <article
      className={classNames("text-white flex article-card relative md:w-1/3", className)}
    >
      {featureImage && (
        <>
          <Image
            fluid={fluidProps}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              zIndex: -1,
            }}
          />

          <div
            style={{
              background: "rgba(0, 0, 0, .5)",
              position: "absolute",
              height: "100%",
              width: "100%",
              zIndex: 0,
            }}
          />
        </>
      )}

      <div
        className={classNames("flex flex-col justify-end px-12 py-12 z-10", {
          "bg-black": !featureImage,
        })}
      >
        <header>
          <p className=" mb-3 opacity-80 text-md uppercase">{category}</p>

          <Heading variant="h3" className="mb-4 md:mb-8 text-4xl">
            {title}
          </Heading>
        </header>

        <footer className="pb-12">
          <Button variant="outlined" link={path}>
            Read More
          </Button>
        </footer>
      </div>
    </article>
  );
}

ArticleCard.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  category: PropTypes.string.isRequired,
  featureImage: PropTypes.shape({}),
};

ArticleCard.defaultProps = {
  className: null,
  featureImage: null,
};

export default ArticleCard;
