import React from "react";
import classNames from "classnames";
import { useStaticQuery, graphql } from "gatsby";

import classes from "./classes";
import logo from "../../images/distinct-footer.png";
import NavLink from "../navigation/NavLink";

const Footer = () => {
  const settings = useStaticQuery(graphql`
    query SiteSettings {
      sanitySiteSettings {
        footerLinks {
          _key
          itemTitle
          externalLink
          itemUrl
        }
      }
    }
  `);

  return (
    <footer>
      <div className="font-standard pb-8 pt-16 bg-distinct-grey font-light text-white">
        <div className="flex flex-col md:flex-row max-w-screen-desktop mx-auto px-6">
          <div className="flex-1 pr-12 pb-5 mb-5 md:mb-0 md:pb-0 md:mr-14 border-distinct-grey-dove border-b md:border-0">
            <p className={classNames(classes.footerHeading)}>Navigation</p>
            <ul className={classNames(classes.footerItems)}>
              {settings.sanitySiteSettings.footerLinks.map(item => (
                <li
                  className="transition duration-200 ease-in-out hover:opacity-80"
                  key={item._key}
                >
                  <NavLink link={item.itemUrl} isExternalLink={item.externalLink}>
                    {item.itemTitle}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex-1 pr-12 pb-5 mb-5 md:mb-0 md:pb-0 border-distinct-grey-dove border-b md:border-0 md:mr-14">
            <p className={classNames(classes.footerHeading)}>Social</p>
            <ul className={classNames(classes.footerItems)}>
              <li>
                <NavLink link="https://facebook.com/distinctperformance/" isExternalLink>
                  Facebook
                </NavLink>
              </li>
              <li>
                <NavLink isExternalLink link="https://instagram.com/distinctperformance/">
                  Instagram
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="flex-1 pr-12 pb-5 mb-5 md:mb-0 md:pb-0">
            <p className={classNames(classes.footerHeading)}>Contact Us</p>
            <ul className={classNames(classes.footerItems)}>
              <li>6B Guiseley Way</li>
              <li>Durham Lane Industrial Estate</li>
              <li>Egglescliffe</li>
              <li className="mb-5">TS16 0RF</li>
              <li>
                T: <a href="tel:07800852347">07800 852347</a>
              </li>
              <li>
                E:{" "}
                <a href="mailto:distinctperform@gmail.com">distinctperform@gmail.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-distinct-grey-mineshaft text-white relative">
        <div className="max-w-screen-desktop mx-auto text-center md:flex justify-between items-center px-6">
          <div className="flex w-full md:w-1/2">
            <img
              className="py-4 h-auto mx-auto md:mx-0 md:mr-12 footer-logo"
              src={logo}
              alt="Distinct Performance Logo"
              style={{ width: 92, height: 108 }}
            />
          </div>
          <p className="font-mono text-sm pb-10 pt-12 md:py-0 md:mb-0">
            Website Crafted by <a href="https://stackupdigital.co.uk" target="_blank">StackUp Digital</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
