const footerHeading = [
  "text-sm",
  "mb-2 md:mb-5",
  "opacity-80",
  "tracking-wider",
  "uppercase",
];
const footerItems = ["md:mb-10", "leading-loose", "tracking-wider"];
const classes = {
  footerHeading,
  footerItems,
};

export default classes;
