/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";

import Container from "../components/container";
import Footer from "../components/footer";
import Navigation from "../components/navigation";
import RelatedArticles from "../components/sections/RelatedArticles";
import SEO from "../components/seo";

import "../styles/app.css";

const Layout = ({ children, homepage, title }) => (
  <>
    <SEO title={title} />

    {!homepage && <Navigation />}

    <main>{children}</main>

    <Container>
      <RelatedArticles />
    </Container>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  homepage: PropTypes.bool,
  title: PropTypes.string,
};

Layout.defaultProps = {
  homepage: false,
  title: "Distinct Performance",
};

export default Layout;
