import React, { useState, useEffect } from "react";
import classNames from "classnames";

import Heading from "../heading";
import NavLink from "./NavLink";

const MyComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (document) {
        if (isOpen) {
          document.querySelector("body").style.overflow = "hidden";
        } else {
          document.querySelector("body").style.overflow = "";
        }
      }
    }
  }, [isOpen]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="z-30">
        <button onClick={() => handleClick()} aria-label="Open Mobile Menu">
          <span className="h-1 w-10 bg-white block mb-2 mr-0 ml-auto" />
          <span className="h-1 w-8 bg-white block mb-2 mr-0 ml-auto" />
          <span className="h-1 w-6 bg-white block mr-0 ml-auto" />
        </button>
      </div>

      <nav
        className={classNames("mobile-nav p-4", {
          open: isOpen,
        })}
      >
        <div>
          <div className="flex flex-col h-64 text-left text-white mt-16 justify-around">
            <Heading variant="h4" className="text-3xl px-6 pb-6">
              Navigation
            </Heading>

            <NavLink variant="homeNav" link="/" handleClick={() => handleClick()}>
              Home
            </NavLink>
            <NavLink variant="homeNav" link="/about-us" handleClick={() => handleClick()}>
              About Us
            </NavLink>
            <NavLink variant="homeNav" link="/services" handleClick={() => handleClick()}>
              Services
            </NavLink>
            <NavLink
              isExternalLink
              link="https://goteamup.com/p/3218555-distinct-performance/"
              variant="homeNav"
              handleClick={() => handleClick()}
            >
              Schedule
            </NavLink>

            <NavLink
              variant="homeNav"
              link="/contact-us"
              handleClick={() => handleClick()}
            >
              Contact us
            </NavLink>
          </div>
        </div>
      </nav>
    </>
  );
};
export default MyComponent;
